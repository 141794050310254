import React from 'react';
import styled from 'styled-components';
import Markdown from './Markdown';
import Section from '../components/Section';
import { H1, P } from '@/components/Typography';
import { PrivacyPolicyPageContent } from '@/pages/privacy-policy';

const PageWrapper = styled.section`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 247, 249, 1) 100%
  );
`;
const PageTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PageTitleP = styled(H1)`
  margin-bottom: 1rem;
  text-align: center;
`;
const Paper = styled.section`
  background-color: white;
  border-radius: 20px;
  padding: 2rem;
  margin: 2rem auto;
  @media (max-width: 1200px) {
    padding: 0;
    background-color: transparent;
  }
`;
interface PrivacyPolicyProps {
  content: PrivacyPolicyPageContent;
}
const PrivacyPolicyContainer: React.FunctionComponent<PrivacyPolicyProps> = ({
  content: { header, body },
}) => (
  <PageWrapper>
    <Section>
      <PageTitle>
        <PageTitleP>
          <span className="accent-text">{header.title1}</span>
          {` `}
          {header.title2}
        </PageTitleP>
        <P>
          {header.subtitle1}
          {` `}
          <span className="accent-text-black-bold">
            {new Date(header.subtitle2).toDateString()}
          </span>
        </P>
      </PageTitle>

      <Paper>
        <Markdown>{body}</Markdown>
      </Paper>
    </Section>
  </PageWrapper>
);

export default PrivacyPolicyContainer;
