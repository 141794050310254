import React from 'react';
import Main from '../containers/Layout';
import privacyPolicyContent from '../../content/pages/privacy-policy.yml';
import PrivacyPolicyContainer from '@/containers/Privacy-policy';

export interface PrivacyPolicyPageContent {
  header: {
    title1: string;
    title2: string;
    subtitle1: string;
    subtitle2: string;
  };
  body: string;
}
const PrivacyPolicyPage: React.FunctionComponent = () => {
  const content = privacyPolicyContent as unknown as PrivacyPolicyPageContent;
  return (
    <Main>
      <PrivacyPolicyContainer content={content} />
    </Main>
  );
};

export default PrivacyPolicyPage;
